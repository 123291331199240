import { MessageType } from '../helpers/enums'
import { _window, convertUpdateEventToEligibilityResponse } from '../helpers/utils'
import { IHandler } from '../interfaces/IHandler'
import { EligibilityUpdateEvent } from '../interfaces/EligibilityUpdateEvent'
export default class F2pHandler implements IHandler {
  private _triple8Instance: any
  private _subscribtionParams: any

  constructor(triple8Instance: any, cid: number) {
    this._triple8Instance = triple8Instance
    this._subscribtionParams = {
      eventName: `/dailyWish/${cid}`,
      successCallback: this._onSuccessCallback,
      errorCallback: this._onErrorCallback,
    }
    return this
  }

  private _onSuccessCallback(message: EligibilityUpdateEvent) {
    console.log('Succesfully received an f2p update eligibility event!', message)

    let validUntilDate = undefined
    if (message?.ValidUntil !== undefined) {
      validUntilDate = new Date(message?.ValidUntil)
    }
    if (validUntilDate === undefined || validUntilDate! < new Date()) {
      console.log('Ignoring the event!')
      return
    }

    let checkEligibilityResp = convertUpdateEventToEligibilityResponse(message)
    _window().updateEligibilityTime(checkEligibilityResp)
  }

  private _onErrorCallback(event: any) {
    console.log('There was an error while processing the F2P event from Tripl8.', event)
  }

  public subscribe() {
    this._triple8Instance.subscribe(
      this._subscribtionParams.eventName,
      this._subscribtionParams.successCallback,
      this._subscribtionParams.errorCallback,
      {}
    )
  }
}
