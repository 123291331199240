import { _window, postCall } from './helpers/utils'
import F2pHandler from './handlers/f2phandler'
import { IHandler } from './interfaces/IHandler'
import { CheckEligibilityResponse } from './interfaces/CheckEligibilityResponse'
import { InitBonusGameResponse } from './interfaces/InitBonusGameResponse'
import { GetRewardsResponse } from './interfaces/GetRewardsResponse'
import { GenerateTokenResponse } from './interfaces/GenerateTokenResponse'
import { Constants } from './helpers/constants'
import { Console } from 'console'

const init = {
  triple8Instance: null,
  fbToken: '',
  cid: 0,
}

let handlers: IHandler[] = []

export async function initCrm(params: any) {
  await getToken(params.userToken)
}

export async function onConnectedToFirebase(params: any) {
  init.fbToken = params.fbToken
  await loadTriple8Messaging(params.fbToken)
  subscribeToMessages()
  updateB2CBanner()
}

export function onDisconnectedFromFirebase() {
  if (init.triple8Instance) {
    ;(init.triple8Instance as any).logOut()
  }
  handlers = [];
}

async function getToken(token: string) {
  if (token === undefined) {
    throw new Error(`failed to call getToken - token Not initialized`)
  }

  let response = await postCall<GenerateTokenResponse>(`process.env.APIURL` + 'GenerateToken', {
    tempToken: token,
  })
  if (response == null) {
    throw new Error(`failed to generate token for f2p sdk.`)
  } else {
    init.cid = response.cid
    localStorage.setItem(Constants.F2PSDKToken, response.token)
  }
}

export async function checkEligibility(): Promise<CheckEligibilityResponse> {
  return postCall(`process.env.APIURL` + 'CheckEligiblity', {})
}

export async function initBonus(): Promise<InitBonusGameResponse> {
  return postCall(`process.env.APIURL` + 'InitBonus', {})
}

export async function getRewards(): Promise<GetRewardsResponse> {
  return postCall(`process.env.APIURL` + 'GetRewards', {})
}

async function loadTriple8Messaging(token: string) {
  if (typeof _window().triple8Messaging !== 'undefined') {
    init.triple8Instance = await _window().triple8Messaging.create(token, {})
  } else {
    throw new Error('Could not load tripl8Messaging!')
  }
}

function subscribeToMessages() {
  handlers.push(new F2pHandler(init.triple8Instance, init.cid))

  handlers.forEach((element) => {
    element.subscribe()
  })
}

async function updateB2CBanner() {
  await checkEligibility()
    .then((resp) => {
      _window().updateEligibilityTime(resp)
    })
    .catch((err) => {
      console.error(err)
      throw new Error('failed to check eligibility')
    })
}
